.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #f6f9fc, #e9eff5);
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
}

.card-body {
  display: flex;
  flex-direction: column;
}

.card-body h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #f1eeee;
  background: linear-gradient(135deg, #a1c98e,teal);
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.form-group {
  flex: 1;
  margin-right: 10px;
}

.form-group:last-child {
  margin-right: 0;
}

label {
  display: block;
  margin-bottom: 5px;
}

select,
input[type="date"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-actions {
  display: flex;
  justify-content: center; 
  margin-top: 20px;
}

.form-actions button {
  padding: 10px 15px;
  background: linear-gradient(135deg, #a2c2e0, #4a8bc2);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-actions button:hover {
  background: linear-gradient(135deg, #a2c2e0, #4a8bc2);
}



.student-list {
  margin-top: 20px;
}

.student-list table {
  width: 95%;
  border-collapse: collapse;
 
}

.student-list table, th, td {
  border: 1px solid #ddd;
}

.student-list th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

.attendance-options {
  display: flex;
  gap: 10px;
}

.attendance-options label {
  display: flex;
  align-items: center;
}

.message {
  margin-top: 20px;
  padding: 10px;
  background-color: #d4edda;
  color: teal;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
}
.student-list h2 {
  text-align: center;
  color: #f1ebeb;
  background: linear-gradient(135deg, #a1c98e,teal);

}
