.card-flip {
  background: linear-gradient(135deg, #c2f0c2 0%, #d3e8f7 100%);
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 200px; /* Adjusted width */
  height: 150px; /* Adjusted height */
  margin-top: 10px;
  color: #333;
  position: relative;
  perspective: 1000px; /* For 3D effect */
}

.card-flip-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card-flip:hover .card-flip-inner {
  transform: rotateY(180deg); /* Flip on hover */
}

.card-flip-front, .card-flip-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 8px;
}

.card-flip-front {
  background: linear-gradient(135deg, #c2f0c2 0%, #d3e8f7 100%);
  padding: 15px;
  border: 1px solid #ccc;
  text-align: center;
  transform: translateY(-10px); /* Move the front side out and down */
}

.card-flip-front img {
  width: 50px; /* Adjust image size */
  height: auto;
}

.card-flip-front h2 {
  margin-top: 10px;
  font-size: 18px; /* Adjusted font size */
  color: #000; /* Font color black */
}

.card-flip-back {
  background: #fff;
  color: #333;
  transform: rotateY(180deg); /* Hide initially */
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000; /* Font color black */
}

/* Updated class detail styles */
.class-info {
  background: linear-gradient(135deg, #c2f0c2 0%, #d3e8f7 100%);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 5px;
  width: 100%;
  height: auto; /* Adjust height for details */
  color: #000; /* Font color black */
}

.class-info h3 {
  margin-bottom: 5px;
  font-size: 12px; /* Adjusted font size */
  color: #000; /* Font color black */
}

.class-info p {
  margin: 2px 0;
  font-size: 12px; /* Adjusted font size */
  color: #000; /* Font color black */
}

.class-info p strong {
  font-weight: bold;
  color: #000; /* Font color black */
}
