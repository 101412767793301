.topics-wrapper {
  padding: 20px;
  margin-top: 20px;
  background-color: #f4f7f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.topics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #333;
}

.topics-add-card h1{
  margin-bottom: 20px;
  font-size: 24px;
  background: linear-gradient(135deg, #a1c98e, teal);
  color: white;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
}

.topics-search-container {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  gap: 10px; 
}

.topics-search-box {
  flex: 1; 
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  outline: none;
  transition: border-color 0.3s;
}

.topics-search-box:focus {
  border-color: #84fab0;
}

.topics-add-button {
  padding: 10px 20px;
  background-color: #1ce666;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.topics-add-button:hover {
  background-color: #79e6a5;
}

.topics-table {
  overflow-x: auto;
}

.topics-table table {
  width: 100%;
  border-collapse: collapse;
}

.topics-table th, .topics-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.topics-table th {
  background-color: #f9f9f9;
}

.topics-add-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.topics-form-group {
  margin-bottom: 15px;
}

.topics-form-group-inline {
  display: flex;
  gap: 15px;
}

.topics-form-group label {
  display: block;
  margin-bottom: 5px;
}


.topics-form-group input,
.topics-form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s;
}


.topics-form-group input:focus,
.topics-form-group textarea:focus {
  border-color: #84fab0;
}
.topics-form-group-inline {
  display: flex;
  gap: 15px;
}

.topics-form-group-inline > div {
  flex: 1;
}

.topics-form-group-inline label {
  display: block;
  margin-bottom: 5px;
}

.topics-form-group-inline select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
}


.topics-form-group-buttons {
  display: flex;
  gap: 10px;
}

.topics-save-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.topics-save-button:hover {
  background-color: #218838;
}

.topics-cancel-button {
  padding: 10px 20px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.topics-cancel-button:hover {
  background-color: #c82333;
}

.topics-error-message {
  color: #dc3545;
  margin-bottom: 15px;
}

.topics-table th{
  background-color: teal;
}