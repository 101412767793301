.pending-assignments-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
 
}

.pending-assignments-table th, .pending-assignments-table td {
  border: 1px solid #ddd;
  padding: 12px;
}

.pending-assignments-table th {
  background-color: #007bff; 
  color: #ffffff; 
}

.pending-assignments-table tr:nth-child(even) {
  background-color: #e9f5ff; 
}

.pending-assignments-table tr:nth-child(odd) {
  background-color: #ffffff; 
}

.pending-assignments-table tr:hover {
  background-color: #d6eaff; 
}
