.exam-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  color: #333;
  margin-top: 20px;
}

h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #f8f3f3;
}

.form-group {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 10px; /* Space between items */
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 10px; /* Space between items */
  margin-bottom: 15px;
}

/* Styling for form fields to align them properly */
.form-group > div,
.form-row > div {
  flex: 1 1 30%; /* Flex basis set to 30% to fit 3 items in a line */
  min-width: 200px; /* Minimum width for better alignment */
}

label {
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
  display: block;
}

/* Styling for selects and inputs */
select,
input[type="number"] {
  width: 100%; /* Full width to fit the container */
  padding: 6px; /* Padding for a more compact look */
  border-radius: 5px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 12px; /* Font size for consistency */
}

/* Radio button group styling */
.radio-group {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.radio-group label {
  font-weight: normal;
  color: #555;
  display: flex;
  align-items: center;
}

.radio-group input {
  margin-right: 5px;
}

/* Button styling */
.button-group,
.buttons {
  text-align: center;
  margin-top: 20px;
}

.button-group button,
.buttons button {
  background: linear-gradient(135deg, #a2c2e0, #4a8bc2);
  color: #fff;
  border: none;
  padding: 8px 16px; /* Padding for compact buttons */
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px; /* Font size for buttons */
}

.button-group button:hover,
.buttons button:hover {
  background: linear-gradient(135deg, #a2c2e0, #105999);
}

.buttons .cancel-button {
  background-color: #dc3545;
}

.buttons .cancel-button:hover {
  background-color: #c82333;
}

/* Student list styling */
.student-list {
  margin-top: 20px;
}

.student-list h3 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.student-list2 {
  margin-top: 20px;
  overflow-x: auto; /* Allows horizontal scrolling if needed */
}

.student-list2 table {
  width: 100%; /* Adjust the width as needed */
  max-width: 100%; /* Ensures the table does not exceed the container width */
  border-collapse: collapse;
  margin: 0 auto; /* Centers the table */
  

}



.student-list table th,
table td {
  border: 1px solid #dddddd;
  padding: 8px; /* Compact table cells */
  text-align: center;

}

.student-list table th {
  background-color: #6d4d07;
  width: fit-content;
  color: #ffffff;
}

.student-list table td input[type="number"] {
  width: 80px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.search-bar{
  margin-bottom: 20px;
}