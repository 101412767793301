.subject-detailing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  padding: 20px;
 
}

.subject-detailing h1 {
  font-size: 2em;
  color: #050505;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.subject-detailing table {
  width: 80%;
  max-width: 800px;
  border-collapse: collapse;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.subject-detailing thead {
  background: linear-gradient(135deg, #007bff 0%, #0056b3 100%);
  color: #fff;
}

.subject-detailing th, .subject-detailing td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.subject-detailing th {
  font-weight: bold;
}

.subject-detailing tr:nth-child(even) {
  background-color: #f9f9f9;
}

.subject-detailing tr:hover {
  background-color: #f1f1f1;
}

.subject-detailing td {
  font-size: 1.1em;
  color: #333;
}
