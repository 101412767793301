.create-assignment {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #333;
  font-family: 'Arial', sans-serif;
  margin-top: 20px;
}

.create-assignment h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  min-width: 200px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group select,
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: inherit;
  font-size: 16px;
}

/* Custom dropdown styles */
.form-group select {
  appearance: none;
  background-color: #fff; /* Ensure the dropdown box remains white */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 40px 8px 15px;
  font-size: 16px;
  color: #333;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIj4KICA8ZGVmcyBzdHlsZT0iZmlsbDpub25lOyIgLz4KICA8cGF0aCBkPSJNNTAsMCAxMDAsNTAgMEw1MCwwIEw1MCw1MCAiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2UtbGluZWNhcD0iI0JCRkZGRiIgLz4KPC9zdmc+Cg==');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px 16px;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

/* Dropdown list items */
.form-group select option {
  background-color: #fff; /* Keep the option background white */
  color: #333;
  padding: 10px 15px;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  border-bottom: 1px solid #eee;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover effect for dropdown items */
.form-group select option:hover {
  background-color: #f1f1f1;
  color: #007bff;
}

/* Scroll effect for dropdown */
.form-group select {
  max-height: 200px;
  overflow-y: auto;
}

.upload-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.upload-group label {
  margin-bottom: 5px;
}

.upload-group input[type="file"] {
  margin-top: 5px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: inherit;
}

button:hover {
  background-color: #0056b3;
}

.notification {
  margin-top: 10px;
  padding: 10px;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  font-family: inherit;
}
