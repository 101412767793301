.due-card {
  background: linear-gradient(135deg, #c2f0c2 0%, #d3e8f7 100%);
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 200px;
  height: 150px;
  margin-top: 10px;
  color: #333;
}

.due-card-front img {
  width: 50px; /* Set the width to a smaller size */
  height: auto; /* Maintain aspect ratio */
}

h3 {
  font-size: 14px;
  color: #000;
}

.total-due-link {
  margin-top: 10px;
  display: inline-block;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.total-due-link:hover {
  text-decoration: underline;
}
