.view-announcements {
    padding: 15px;
    background: linear-gradient(135deg, #f9f9f9 0%, #f1f1f1 100%);
    min-height: 100vh;
    margin-top: 20px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .view-announcements-header {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .create-announcement-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
  
  .create-announcement-button:hover {
    background-color: #0056b3;
    transform: scale(1.03);
  }
  
  .announcements-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .announcement-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
  .announcement-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-3px);
  }
  
  .announcement-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .announcement-class, .announcement-section {
    font-weight: bold;
    color: #007bff;
    font-size: 14px;
  }
  
  .announcement-title {
    font-size: 18px;
    margin: 8px 0;
    color: #333;
    border-bottom: 1px solid #007bff;
    padding-bottom: 4px;
  }
  
  .announcement-message {
    font-size: 14px;
    margin-bottom: 10px;
    color: #555;
  }
  
  .announcement-details {
    font-size: 12px;
    color: #777;
  }
  
  .announcement-details span {
    display: block;
    margin-bottom: 3px;
  }
  
  .delete-icon-button {
    background: none;
    border: none;
    cursor: pointer;
    color: red;
    font-size: 24px;
    padding: 0;
  }
  
  .delete-icon-button:hover {
    opacity: 1;
  }
  