/* Component Card Styles */
.component-card {
  background: linear-gradient(135deg, #e6d0f0 0%, #f8c0c0 100%);
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 200px; 
  height: 150px;
  margin-top: 10px;
  color: #333;
  position: relative;
  perspective: 1000px; 
}

.component-card-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.component-card:hover .component-card-inner {
  transform: rotateY(180deg); 
}

.component-card-front{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 8px;
}

.component-card-back{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 8px;
}

.component-card-front {
  background: linear-gradient(135deg, #e6d0f0 0%, #f8c0c0 100%);
  padding: 20px; 
  border: 1px solid #ccc;
  text-align: center;
  transform: translateY(-10px); 
  width: 100%;
}

.component-card-front img {
  width: 80px; 
  height: auto;
}

.component-card-front h2 {
  margin-top: 10px;
  font-size: 18px; 
  color: #000; 
}

.component-card-back {
  background: #fff;
  color: #333;
  transform: rotateY(180deg); 
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; 
}

.subject-details {
  background: linear-gradient(135deg, #e6d0f0 0%, #f8c0c0 100%);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px; 
  margin-bottom: 10px; 
  width: 200px;
  color: #000; 
}

.subject-details h3 {
  margin-bottom: 5px;
  font-size: 14px; 
  color: #000; 
}

.subject-details p {
  margin: 2px 0;
  font-size: 14px; 
  color: #000; 
}

.subject-details p strong {
  font-weight: bold;
  color: #000;
}


.more-info-button {
  margin: 20px 0;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.more-info-button:hover {
  background-color: #0056b3;
}

/* Table Styles */
.subjects-table {
  margin-top: 20px;
}

.subjects-table table {
  width: 100%;
  border-collapse: collapse;
}

.subjects-table th,
.subjects-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.subjects-table th {
  background-color: #007bff;
  color: white;
}

.more-info-link {
  display: block;
  margin-top: 10px;
  font-size: 12px;
  text-decoration: underline;
  color: #007bff; 
}
