.assignment-card2 {
  background: linear-gradient(135deg, #c2f0c2 0%, #1485d6 100%);
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 200px;
  height: 150px;
  margin-top: 10px;
  color: #333;
  position: relative;
  perspective: 1000px; /* For 3D effect */
}

.assignment-card-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.assignment-card2:hover .assignment-card-inner {
  transform: rotateY(180deg); /* Flip on hover */
}

.assignment-card-front, .assignment-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 8px;
}

.assignment-card-front {
  background: linear-gradient(135deg, #c2f0c2 0%, #d3e8f7 100%);
  padding: 15px;
  border: 1px solid #ccc;
  text-align: center;
  transform: rotateY(0deg); /* Initial state */
}

.assignment-card-front img {
  width: 50px; /* Adjusted image size */
  height: auto;
}

.assignment-card-front h2 {
  margin-top: 10px;
  font-size: 16px; /* Adjusted font size */
  color: #000; /* Font color black */
}

.assignment-card-back {
  background: linear-gradient(135deg, #c2f0c2 0%, #d3e8f7 100%);
  color: #333;
  transform: rotateY(180deg); /* Initial state */
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000; /* Font color black */
}

.assignment-detail {
  text-align: left; /* Align text to the left */
  width: 100%; /* Take full width */
  background: linear-gradient(135deg, #c2f0c2 0%, #d3e8f7 100%);
  color: #000; /* Font color black */
  font-size: 14px; /* Adjusted font size */
}

.assignment-detail p {
  font-size: 12px; /* Adjusted font size */
  color: #000; /* Font color black */
}

.progress-bar {
  height: 10px; /* Adjust height for progress bar */
  background: #f3f3f3;
  border-radius: 10px;
  margin: 10px 0;
  position: relative;
  width: 100%;
}

.progress-bar-fill {
  height: 100%;
  background: #007bff; /* Color for progress */
  border-radius: 10px;
  transition: width 0.3s ease;
}
