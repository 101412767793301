.sidebar {
  width: 250px; 
  background: linear-gradient(135deg, #fda085 0%, #f6d365 100%);
  height: 100vh; 
  padding: 15px;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto; 
  box-sizing: border-box; 
}

.sidebar-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.sidebar-header Avatar {
  width: 100%;
  height: 100%;
  margin-right: 10px;
}

.sidebar h2 {
  font-size: 18px; 
  color: #ffffff;
  text-align: center;
  margin: 0; 
  font-family: 'Roboto Slab', serif; 
  font-weight: 800; 
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); 
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 18px;
 
}

.sidebar li {
  margin: 10px 0;
  padding: 4px;
  border-radius: 8px;
  transition: background 0.3s, color 0.3s;
  cursor: pointer;
  font-size: 16px;

}

.sidebar .icon {
  display: flex;
  align-items: center;
}

.sidebar .icon svg {
  margin-right: 10px;
}

.sidebar a {
  text-decoration: none;
  color: #fff; 
  display: block; /* Keep link content in block */
  padding: 2px;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.sidebar a:hover {
  background: #575757;
}

.menu-heading {
  display: flex; 
  align-items: center; /* Vertically center items */
  font-weight: bold;
  cursor: pointer;
  color: #fff;
}

.menu-heading .dropdown-icon {
  margin-left: auto; 
}



/* Icon and text inline */
.sidebar .submenu li .icon {
  margin-right: 8px; /* Spacing between icon and text */
}

/* Submenu links styling */
.sidebar .submenu li a {
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
}

/* Hover effect for submenu links */
.sidebar .submenu li a:hover {
  background: #666;
}



.submenu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none; 
}

.submenu li {
  margin: 0;
}

.submenu a {
  padding-left: 30px;
  background: transparent;
  color: #fff; 
}


.submenu-show {
  display: block;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 200px;
  }
}

li a[href='/view-announcements'] {

  color: #ffffff;
  padding: 1px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-weight: 650; 


}

li a[href='/view-announcements'] .icon {
  margin-right: 10px;
}


li a[href='/dashboard'] {
 
  color: #ffffff;
  padding: 1px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-size: 18px;
  font-weight: 700;
 
}

li a[href='/dashboard'] .icon {
  margin-right: 15px;
}



.sidebar ul li .icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.sidebar ul li .menu-text {
  display: inline-block;
  vertical-align: middle;
}

.sidebar ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff; /* Adjust as per your color scheme */
}
