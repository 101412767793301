.due-details {
  padding: 20px;
  background: linear-gradient(to right, #d0e6f4, #b3d9f7);

  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  margin-top: 10px;
}

.due-details h1{
font-size: large;
text-align: center;
color: #0a0a0a;
}
.due-detail-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px; 
}

.due-detail-table th,
.due-detail-table td {
  border: 1px solid #ddd;
  padding: 12px; /* Increased padding for better readability */
  text-align: left;
}

.due-detail-table td:first-child,
.due-detail-table th:first-child {
  width: 2%; 
  text-align: center;
}
.due-detail-table th {
  background-color: #f0f3f8;
  font-weight: bold;
  color: #0a0a0a; 
  
}

.due-detail-table tr:nth-child(even) {
  background-color: #f9f9f9; 
}

.due-detail-table tr:hover {
  background-color: #f1f1f1; 
}

.search-export-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin-bottom: 20px; 
}

.search-bar {
  width: 90%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-right: 10px; 
}

.export-button {
  padding: 10px 12px; 
  border-radius: 4px;
  border: 1px solid #007bff;
  background-color: #007bff;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 10px; 
  width: 20%;
}

.export-button:hover {
  background-color: #0056b3;
}

.due-details button{
  width: 50%;
  
}


.tabs {
  display: flex;
  margin-bottom: 20px; 
}

.tab{
  flex: 1;
  padding: 10px 20px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #007bff;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 10px; 
}

.tab.active {
  background: #033366;
  color: #fff;
  border: 1px solid #007bff;
}

.tab:last-child {
  margin-right: 0; 
}
