.view-assignment {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  position: relative;
}

.assignment-header {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #000;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filter-container select {
  padding: 6px; 
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-right: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  position: relative;
}

table th,
table td {
  padding: 2px 6px;
  text-align: left;
  border: 1px solid #ddd;
  font-size: 10px; 
  max-width: 100px; 
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}


table td:hover {
  max-width: none;
  white-space: normal;
  background-color: #f9f9f9;
}


table td:hover::after {
  content: attr(data-full-text);
  position: absolute;
  left: 0;
  top: 100%;
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: normal;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
  max-width: 300px;
  overflow-wrap: break-word;
  display: block;
}

.edit-form {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.edit-form h3 {
  margin-top: 0;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 6px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.form-group textarea {
  resize: vertical;
  height: 60px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px; 
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
