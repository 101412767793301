
.navbar-wrapper {
  width: 100%;
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  color: #fff;
  padding: 5px 10px;
  position: relative; 
}


.main-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px; 
}

.navbar-brand-section h1 {
  margin: 0;
  font-size: 16px; 
}


.user-section {
  display: flex;
  align-items: center;
  position: relative; 
}


.profile-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 15px; 
}

.profile-img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
}


.bell-container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}


.bell-img {
  width: 20px; /* Smaller bell icon */
  height: 20px;
}

/* Badge for unread messages */
.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 10px;
}

/* NavbarStyles.css */

.navbar-wrapper {
  width: 100%;
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
  color: #fff;
  padding: 5px 10px;
  position: relative; 
}

.navbar-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px; 
}

.navbar-brand {
  margin: 0;
  font-size: 16px; 
}

.user-info {
  display: flex;
  align-items: center;
  position: relative; 
}

.profile-info {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 15px; 
}

.profile-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
}

.notifications {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.notification-icon {
  width: 20px; /* Smaller bell icon */
  height: 20px;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 10px;
}


.dropdown-menu {
  position: absolute;
  top: 100%; 
  left: 0; 
  background-color: #ffffff; /* Light background for better contrast */
  color: #333;
  border-radius: 6px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slightly larger shadow for depth */
  z-index: 1000;
  min-width: 200px; /* Adjusted width */
  margin-top: 10px; 
  display: none; 
  overflow: hidden; /* Ensures dropdown content does not overflow */
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fda085;
}

.dropdown-menu ul li {
  padding: 12px 16px; /* More padding for larger click areas */
  cursor: pointer;
  font-size: 14px; 
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
}

.dropdown-menu ul li:hover {
  background-color: #f0f0f0;
  color: #333; /* Darker text color on hover */
}
