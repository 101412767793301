.notice-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  width: 190px;
}

.notice-card h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.notice-card p {
  margin-bottom: 10px;
  line-height: 1.5;
}
.notice-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}


.notice-card .notice-content {
  border-left: 3px solid #269c92; 
  padding-left: 10px;
}

.notice-card .notice-details {
  margin-top: 10px;
  font-size: 14px;
  color: #777;
}

.notice-card .notice-author {
  font-weight: bold;
}
