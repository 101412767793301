.lessons-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.lessons-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.lessons-search-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.lessons-search-box {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
}

.lessons-add-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.lessons-add-button:hover {
  background-color: #45a049;
}

.lessons-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.lessons-table th {
  background: linear-gradient(135deg, #a1c98e, teal);
  color: #fff;
  padding: 12px 15px;
  text-align: left;
  font-weight: bold;
}

.lessons-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.lessons-action-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  transition: background-color 0.3s ease;
}

.lessons-action-button.edit {
  background-color: #2196F3;
  color: white;
}

.lessons-action-button.edit:hover {
  background-color: #1976D2;
}

.lessons-action-button.delete {
  background-color: #f44336;
  color: white;
}

.lessons-action-button.delete:hover {
  background-color: #d32f2f;
}

.lessons-add-card {
  position: relative;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background: #d5f5c7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.lessons-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none; 
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #888;
  outline: none; 
  box-shadow: none; 
}

.lessons-close-button:hover {
  color: #555;
  background: none; 
  box-shadow: none; 
}

.lessons-close-button:focus {
  outline: none; 
  background: none;
  box-shadow: none;
}

.lessons-add-card h1 {
  margin-bottom: 20px;
  font-size: 24px;
  background: linear-gradient(135deg, #a1c98e, teal);
  color: white;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
}

.lessons-error-message {
  color: #e53935;
  margin-bottom: 15px;
}

.lessons-form-group {
  margin-bottom: 15px;
}

.lessons-form-group-inline {
  display: flex;
  gap: 10px;
}

.lessons-form-group-inline > div {
  flex: 1;
}

.lessons-form-group-inline label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.lessons-form-group-inline select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.lessons-form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.lessons-form-group input,
.lessons-form-group select,
.lessons-form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.lessons-form-group textarea {
  height: 100px;
  resize: none;
}

.lessons-save-button {
  width: 50%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block; 
  margin: 0 auto; 
}

.lessons-save-button:hover {
  background-color: #45a049;
}


