/* General reset and box-sizing */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Body styling */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f0;

}

/* Main container styling */
.main-container {
  display: flex;
  gap: 20px;
  padding: 20px;
}

/* Content styling */
.content {
  flex: 1;
  padding: 20px;
  background: #e7d5bf;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.content:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}


/* Upcoming events styling */
.upcoming-events {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 200px;
}

.upcoming-events:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.upcoming-events h2 {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.event-card {
  background-color: #e3f2fd;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 15px;
}

.event-title {
  font-size: 14px;
  font-weight: bold;
  color: #1976d2;
  margin-bottom: 5px;
}

.event-date {
  font-size: 14px;
  color: #333;
}

/* App.css */
.main-container {
  display: flex;
  height: 150vh;
}

.left-sidebar {
  flex: 0 0 220px;
}

.content {
  flex: 1;
  padding: 20px;
  background: #fdede0;
  height: 1500px;
  width: 800px;
}

.card-content {
  margin-bottom: 10px;
}

.dashboard {
  display: flex;
  flex-wrap: wrap;
}

.card-content {
  margin: 10px;
  margin-left: 70px;
}

.main-container {
  display: flex;
}

.left-sidebar {
  width: 200px;
}

.content {
  flex-grow: 1;
}

.right-sidebar {
  width: 200px;
  transition: width 0.3s ease;
}

.right-sidebar.hidden {
  width: 0;
  overflow: hidden;
}

.toggle-sidebar {
  position: fixed;
  top: 10px;
  right: 20px;
  background-color: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  outline: none; /* Prevent the blue outline */
}

.toggle-sidebar img {
  width: 20px;
  height: 20px;
  filter: none; /* Ensure no color filter is applied */
}

.toggle-sidebar:focus,
.toggle-sidebar:active {
  outline: none; /* Prevent the blue outline */
}



.login-body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  /* background: #000; */
}

.login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 480px;
  padding: 90px 45px 60px 45px;
  transform: translate(-50%, -50%);
  background: rgb(60 90 229);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  filter: drop-shadow(5px 5px 5px orange);
  margin-left: 400px;
}

/* .login-form @media (max-width: 600px) {
  width: 282px;
  padding: 90px 15px 15px 15px;
} */

.login-form .logo-login img {
  width: 99px;
  height: 95px;
  position: absolute;
  top: -57px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border: 15px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.7);
  border-radius: 50%;
}

.login-form h2 {
  color: #fff;
  font-size: 30px;
  margin: 0;
  text-align: center;
  font-weight: 600;
  margin-bottom: 40px;
}

.login-form .form-input {
  position: relative;
  margin: 20px 0;
}

.login-form .form-input label {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.login-form .form-input input {
  outline: none;
  height: 40px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: transparent;
  font-size: 18px;
  border: none;
  color: #fff;
  border-bottom: 1px solid #fff;
}

.login-form .form-input input::placeholder {
  color: #fff;
}

.login-form .form-input input[type="submit"] {
  background: #fff;
  color: #000;
  border: none;
  cursor: pointer;
  text-transform: capitalize;
  line-height: 1;
  font-size: 18px;
  font-weight: 700;
  border-radius: 20px;
  transition: 0.4s;
}

.login-form .form-input input[type="submit"]:hover {
  background: rgb(242, 190, 48);
}

@media (max-width: 768px) {
     
  .home{
    position: relative;
    height: 100vh;
    width: 50%;
    /* background-image: url("C:\Users\ASUS\OneDrive\Documents\React and ASP\School_Management\src\21429.jpg"); */
    /* background-image: url("C:\Users\shwet\Desktop\Cloned WSMS\School_Management\src\21429.jpg"); */
   background-size: cover;
    background-position: center;
  }  
  .login-form{
    margin-left: 1px;
    width: 340px;
  }
  
}

.home{
  position: relative;
  height: 100vh;
  width: 100%;
  /* background-image: url("C:\Users\ASUS\OneDrive\Documents\React and ASP\School_Management\src\21429.jpg"); */
  /* background-image: url("C:\Users\shwet\Desktop\Cloned WSMS\School_Management\src\21429.jpg"); */
  background-size: cover;
  background-position: center;

} 


.login-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}