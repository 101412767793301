/* Overall container for the announcement forms */
.announcement-forms {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
  margin-top: 20px;
}

/* Header styling */
.announcement-header {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}


.dropdown-section-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.dropdown-section {
  flex: 1; 
  min-width: 200px; 
}


.dropdown-label {
  font-size: 16px;
  font-weight: 600;
  color: #555;
  margin-bottom: 8px;
  display: block;
}

.dropdown {
  width: 100%;
}

.react-select__multi-value {
  display: inline-flex; 
  flex-wrap: nowrap; 
  margin-right: 5px; 
}

.react-select__multi-value__label {
  white-space: nowrap; 
}

.react-select__multi-value__remove {
  cursor: pointer;
}


.title-section {
  width: 100%;
  margin-bottom: 20px;
}

.title-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}


.message-section {
  width: 100%;
  margin-bottom: 20px;
}

.message-editor {
  width: 100%;
  min-height: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Error message styling */
.error-message {
  color: #d9534f;
  font-size: 14px;
  margin-bottom: 20px;
}

/* Button section styling */
.button-section {
  display: flex;
  justify-content:center;
  width: 100%;
  gap: 2px;
}

/* Button styling */
.save-button,
.cancel-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  transition: background-color 0.3s, color 0.3s;
}

.save-button {
  background-color: #28a745;
  color: #fff;
}

.save-button:hover {
  background-color: #218838;
}

.cancel-button {
  background-color: #dc3545;
  color: #fff;
}

.cancel-button:hover {
  background-color: #c82333;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #28a745;
  color: #fff;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.popup-content {
  font-size: 16px;
}

.popup-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.popup-close-button:hover {
  color: #e6e6e6; /* Slightly lighter color for hover effect */
}
