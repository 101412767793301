/* Container for the entire chart component */
.attendance-chart-container {
  padding: 40px; /* Increased padding for more spacing */
  background: #fafafa; /* Light blue-gray background for the container */
  width: 800px; /* Set a fixed width for the container */
  max-width: 3500px; /* Ensure the container does not exceed this width */
  margin-left: -90px; /* Shift container 50px to the left */
  box-sizing: border-box; /* Ensure padding and border are included in the total width */
}



/* Styling for month options section */
.month-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
}

.month-options.hidden {
  display: none;
}

.month-options {
  background: #f8f9fa;
  border: 1px solid #90caf9;
  padding: 12px 20px; /* Further increased padding */
  margin: 4px; /* Added some margin between buttons */
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  font-size: 12px; /* Further increased font size */
}

.month-option.active {
  background-color: #01233f; /* Active background color */
  color: #ffffff; /* Active text color */
  font-weight: bold;
}

/* Styling for view type options */
.view-options {
  display: flex;
  margin-bottom: 20px;
  margin: 8px;
  padding: 4px; /* Increased padding */
  border-radius: 12px;
  justify-content: center;
  gap: 15px;
}



.view-options label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.view-option {
  background: #81c784;
  border: 1px solid #81c784;
  padding: 12px 22px; /* Further increased padding */
  margin: 0 10px; /* Further increased margin */
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  font-size: 16px; /* Further increased font size */
  align-items: center;
}

.view-option.active {
  background: #4caf50;
  color: #ffffff;
  font-weight: bold;
}

.view-options input[type="radio"] {
  margin-right: 8px; /* Add gap between radio button and text */
}

/* Styling for the chart container */
.chart-wrapper {
  width: 100%;
  max-width: 2400px; /* Further increased max-width for the chart wrapper */
  background: #ffffff;
  padding: 70px; /* Further increased padding */
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
  margin-top: 10px;
  
}

.chart-container {
 max-width: 3000px;
 width: 600px;
}

#attendance-overview {
  color: #007bff; /* Example color */
  font-size: 24px; /* Example font size */
}
.select-container {
  display: flex; /* Aligns children (select elements) in a row */
  gap: 10px; /* Adds space between the select elements */
}

.custom-select {
  padding: 8px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
}
