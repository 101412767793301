/* Container Styles */
.view-attendance {
  padding: 20px;
  background: linear-gradient(135deg, #f5f5f5, #ffffff);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.view-attendance h1 {
  color: #ffffff;
  margin: 20px auto;
  font-size: 2rem;
  text-align: center;
  max-width: 80%;
  background: linear-gradient(135deg, #a1c98e, teal);
  padding: 15px;
  border-radius: 8px;
}

.view-attendance h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.attendance-details h3 {
  color: #f5f5f5;
  text-align: center;
  background: teal;
  font-size: large;
}

.student-info h2 {
  background: linear-gradient(135deg, #a1c98e, teal);
}

/* Controls Styles */
.controls {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controls input,
.controls select {
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.controls input:focus,
.controls select:focus {
  border-color: #00796b;
  box-shadow: 0 0 8px rgba(0, 121, 107, 0.2);
}

.controls input {
  width: 60%;
}

.controls select {
  width: 35%;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

table th {
  background-color: #00796b;
  color: #fff;
  font-size: 14px;
}

table td {
  font-size: 14px;
}

table tr:nth-child(even) {
  background-color: #f9f9f9;
}

table tr:hover {
  background-color: #f1f1f1;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.modal {
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  width: 90%;
  max-width: 800px;
  position: relative;
  overflow: auto;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #0f0f0f;
  transition: color 0.3s;
}

.modal-close:hover {
  color: #00796b;
}

/* Student Details Styles */
.student-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.student-picture {
  flex: 0 0 120px;
  text-align: center;
}

.student-picture img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #ddd;
  object-fit: cover;
}

.student-info {
  flex: 1;
  padding: 10px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.info-item {
  font-size: 14px;
  width: 48%;
}

.info-item strong {
  font-weight: 600;
  color: #00796b;
}

/* Attendance Details Styles */
.attendance-details {
  margin-top: 20px;
}

.attendance-details h2 {
  background: linear-gradient(135deg, #a1c98e, teal);
}

.attendance-table {
  width: 80%;
  max-width: 500px;
  border-collapse: collapse;
  font-size: 10px;
  margin: 0 auto;
}

.attendance-table th,
.attendance-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.attendance-table th {
  background-color: #00796b;
  color: #fff;
  font-weight: 600;
}

.attendance-table td {
  height: 24px;
}

/* Status Styles */
.status.p {
  background-color: #4caf50;
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
}

.status.a {
  background-color: #f44336;
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
}

.status.w {
  background-color: #ff9800;
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
}

/* Additional Styling */
button,
a {
  transition: background-color 0.3s, color 0.3s;
}

button:hover,
a:hover {
  background-color: #00796b;
  color: #fff;
}

@media (max-width: 768px) {
  .view-attendance {
    padding: 15px;
  }

  .controls input,
  .controls select {
    width: 100%;
    margin-bottom: 10px;
  }

  .student-details {
    flex-direction: column;
    align-items: flex-start;
  }

  .student-picture {
    margin-bottom: 15px;
  }

  .attendance-table {
    width: 100%;
  }
}
